import { Link } from "gatsby"
import React, { useContext, useEffect, useState } from "react"
import Close from "../assets/image/close-gray.svg"
import { StateContext } from "../context/stateProvider"
import { inferLocation } from "./nav"
import NavLink from "./nav/navlink"
import Logo from "../assets/image/logo.svg"
import newLogo from "../assets/image/newLogo.svg"
import { webappHost } from "../../config"

function SideNav(props) {
  const { hidden, setHidden } = useContext(StateContext)

  return (
    <div>
      <div className={`toast-box z-30 ${hidden && "hidden"}`}>
        <div className="sidenav bg-white pl-4 p-2 h-screen">
          <div className=" overflow-auto scroll h-full px-4 pt-4">
            <div className="w-full flex justify-end">
              <img
                src={Close}
                className="h-8 w-8"
                onClick={() => setHidden(!hidden)}
              />
            </div>
            <div className="flex flex-col justify-between h-5/6">
              <div>
                <div className="flex items-center mt-4 mb-4">
                  <div>
                    <Link>
                      {" "}
                      <img src={newLogo} className="h-8" />
                    </Link>
                  </div>
                  {/* <p className="text-3xl font-semibold ml-3 mt-1 blue-dark2">
                    <Link>SkillsTrainer</Link>
                  </p> */}
                </div>
                <ul
                  className="inline-block text-lg mt-1 margin-3vw"
                  onClick={() => setHidden(true)}
                >
                  <NavLink className="mt-3" to="/" page="home">
                    Home
                  </NavLink>
                  <NavLink className="mt-3" to="/courses" page="courses">
                    Courses
                  </NavLink>
                  <NavLink className="mt-3" to="/blogs" page="blogs">
                    Blogs
                  </NavLink>
                  <NavLink className="mt-3" to="/about" page="about">
                    About
                  </NavLink>
                </ul>
              </div>
              <div className="flex flex-col">
                <button className="bg-orange-light text-japanese_indigo font-semibold rounded-lg text-sm px-1 py-2 mt-3">
                  <a href={webappHost + "/enterprise"} target="_blank">
                    Work Partner
                  </a>
                </button>
                <button className="bg-orange-light text-japanese_indigo font-semibold rounded-lg text-sm px-1 py-2 mt-3">
                  <a href={webappHost + "/login"} target="_blank">
                    Login
                  </a>
                </button>
                <button className="bg-orange text-white font-semibold rounded-lg text-sm px-1 py-2 mt-3">
                  <a href={webappHost} target="_blank">
                    Join for Free
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideNav
