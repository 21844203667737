import React from "react"
import Nav from "./nav"
import Footer from "./footer"
import HelmetProvider from "./helmetPovider"

export default function Layout(props) {
  return (
    <div className="relative">
      <HelmetProvider title={props.title} />
      <Nav lang={props.lang} />
      {props.children}
      <Footer lang={props.lang} />
    </div>
  )
}
