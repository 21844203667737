import { Link } from "gatsby"
import React, { useContext } from "react"
import { StateContext } from "../../context/stateProvider"
import { NavigationContext } from "../../context/Navigation"

export default function NavLink(props) {
  const { activePage, setActivePage, language } = useContext(StateContext)
  const {currentLanguage: lang, setPath} = useContext(NavigationContext)

  let { to, children, page, className } = props

  // if the route is relative to root, then append the language to the route
  const link =
    to[0] === "/" ? (language !== "en" ? "/" + language : "") + to : to

  return (
    <li
      className={`${className} ${page === activePage ? "nav-link-active" : ""}`}
    >
      <button  onClick={
        () => {
          setPath(to)
          setActivePage(page)}
        }>
        {children}
      </button>
    </li>
  )
}
