import React, { useContext } from "react"
import Menu from "../assets/image/menu.svg"
import SideNav from "./sideNav"
import NavLink from "./nav/navlink"
import { NavigationContext } from "../context/Navigation"
import { MultiLangBody, MultiLangField } from "skillstrainer-resource-library"
import Link from "./Link"
import LanguageSwitcher from "./LanguageSwitcher"
import { StateContext } from "../context/stateProvider"
import { webappHost } from "../../config"
import "../data.json"

const Nav = props => {
  const newLogo = require("../assets/image/newLogo.svg").default
  const poweredByLogo = require("../assets/image/poweredByLogo.svg").default

  const { currentLanguage: lang } = useContext(NavigationContext)
  const { setHidden } = useContext(StateContext)

  const { partner } = require("../data.json")
  const logo = partner ? poweredByLogo : newLogo

  return (
    <MultiLangBody _key={"component.nav." + lang}>
      <div className="absolute xl:top-6 left-0 right-0 z-10 content-nav mx-auto">
        <div className="bg-white py-3 pr-2 pl-6 shadow-lg flex items-center justify-between rounded-xl hidden xl:flex">
          <div className="flex items-center">
            <div className="cursor-pointer">
              <Link to={"/"}>
                {" "}
                <img src={logo} className="h-8" />{" "}
              </Link>
            </div>
            {partner && (
              <div className="ml-4  object-fill">
                <img src={partner.logo} className=" max-h-10" />
              </div>
            )}

            <ul className="inline-block text-lg mt-1 ml-10 text">
              <NavLink className="inline-block mr-6" to={"/"} page="home">
                <MultiLangField name={"home"}>Home</MultiLangField>
              </NavLink>
              <NavLink className="inline-block" to={"/courses"} page="courses">
                <MultiLangField name={"courses"}>Courses</MultiLangField>
              </NavLink>
              {/* <NavLink
                className="inline-block mr-12"
                to={"/blogs"}
                page="blogs"
              >
                <MultiLangField name={"blogs"}>Blogs</MultiLangField>
              </NavLink>
              <NavLink
                className="inline-block mr-12"
                to={"/about"}
                page="about"
              >
                <MultiLangField name={"about"}>About</MultiLangField>
              </NavLink> */}
            </ul>
          </div>

          <div className="flex">
            <LanguageSwitcher />

            {!partner && (
              <button className="text-sm mx-4 mr-0">
                <a href={webappHost + "/enterprise"} target="_blank">
                  <MultiLangField name={"work_partner"}>
                    Work Partner
                  </MultiLangField>
                </a>
              </button>
            )}

            <button className="text-sm mx-4">
              <a href={webappHost + "/login"} target="_blank">
                <MultiLangField name={"login"}>Login</MultiLangField>
              </a>
            </button>
            <button className="text-sm bg-orange text-white font-semibold rounded-lg px-4">
              <a href={webappHost} target="_blank">
                <MultiLangField name={"joinForFree"}>
                  Join for Free
                </MultiLangField>
              </a>
            </button>
          </div>
        </div>

        <div className="block xl:hidden bg-white p-5 shadow-lg w-full">
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center">
              <div>
                <Link to="/">
                  {" "}
                  <img src={logo} className="h-8" />
                </Link>
              </div>
              {partner && (
                <div className="ml-4  object-fill">
                  <img src={partner.logo} className=" max-h-10" />
                </div>
              )}
            </div>
            <img src={Menu} onClick={() => setHidden(false)} className="h-6" />
          </div>
        </div>
      </div>
      <SideNav />
    </MultiLangBody>
  )
}

export default Nav
