import React from "react"
import Helmet from "react-helmet"

function HelmetProvider(props) {
  return (
    <Helmet
      title={props.title}
      link={
        [
          //   {
          //     rel: "stylesheet",
          //     href:
          //       "https://cdn.jsdelivr.net/npm/uikit@3.6.17/dist/css/uikit.min.css",
          //   },
          // ]}
          // script={[
          //   {
          //     src: "https://cdn.jsdelivr.net/npm/uikit@3.6.22/dist/js/uikit.min.js",
          //   },
          //   {
          //     src:
          //       "https://cdn.jsdelivr.net/npm/uikit@3.6.22/dist/js/uikit-icons.min.js",
          //   },
        ]
      }
    />
  )
}

export default HelmetProvider
