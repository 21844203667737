import { Listbox } from "@headlessui/react"
import { languages, languagesInitials, reverseLanguages } from "../../config"
import Down from "../assets/image/down-orange.svg"
import { CheckIcon } from "@heroicons/react/solid"
import React, { useContext, useState } from "react"
import { NavigationContext } from "../context/Navigation"
import { window } from "browser-monads"
import { partialObject } from "../utils/object"

export default function LanguageSwitcher(props) {
  const { currentLanguage: lang, setLanguage } = useContext(NavigationContext)

  const [selectedButton, setSelectedButton] = useState(0)
  const { pathname } = window.location
  const chunk1 = pathname.split("/")

  let currentlangCode =
    Object.values(languages).find(e => e === chunk1[1]) || ""

  if (!currentlangCode) {
    currentlangCode = "en"
  }

  return props.dropdown ? (
    <Listbox
      onChange={value => {
        setLanguage(value)
      }}
    >
      <Listbox.Button
        className="relative py-6 pl-3 pr-10 text-left bg-white cursor-pointer focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm"
        style={{ width: "150px", maxWidth: "100%" }}
      >
        <span className="block truncate text-md">{reverseLanguages[lang]}</span>
        <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
          <img src={Down} className="h-8" />
        </span>
      </Listbox.Button>
      <Listbox.Options
        className="absolute py-1 mt-1 overflow-auto scroll text-sm bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
        style={partialObject(props.direction === "up", {
          top: "0",
          transform: "translateY(-100%)",
        })}
      >
        {Object.keys(languages).map(label => {
          const langValue = languages[label]
          return (
            <Listbox.Option
              key={label}
              value={langValue}
              className={({ active }) =>
                `${active ? "text-yellow-900 bg-yellow-100" : "text-gray-900"}
                          cursor-default select-none relative py-2 pl-10 pr-4`
              }
            >
              {({ selected, active }) => (
                <>
                  <span
                    className={`${
                      selected ? "font-medium" : "font-normal"
                    } block truncate text-sm pl-4`}
                  >
                    {label}
                  </span>
                  {selected ? (
                    <span
                      className={`${
                        active ? "text-yellow-600" : "text-yellow-600"
                      } absolute inset-y-0 left-0 flex items-center pl-3`}
                    >
                      <CheckIcon className="w-8 h-8" aria-hidden="true" />
                    </span>
                  ) : null}
                </>
              )}
            </Listbox.Option>
          )
        })}
      </Listbox.Options>
    </Listbox>
  ) : (
    <div className="max-w-max border rounded-lg flex items-center ">
      {Object.values(languages).map((code, index) => {
        return (
          <p
            className="text-sm nav-link py-3 px-4 border-r "
            style={
              currentlangCode == code
                ? { color: "#e76f51" }
                : { color: "black" }
            }
            onClick={() => {
              setLanguage(code)
              setSelectedButton(index)
            }}
          >
            {languagesInitials[code]}
          </p>
        )
      })}
    </div>
  )
}
